@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #ffc983; /* Light grey */
    border-top: 10px solid #f28f22; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  .spinner-container{
    position: relative;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }