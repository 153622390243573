html{
    height: 100%;
}
body{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #dadada;
    margin: 0px;
    font-family: 'open sans', sans-serif;
}

#top{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

header{

    background-color: #ffffff;
    height: 350px;
    display: flex;
    justify-content: center;
    width: 100%;
}

#vcardHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#title{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

nav{
    height: 75px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

nav a{
    flex-grow: 1;
    display: flex;
    border: 1px solid black;
    color: black;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

#addContact{
    background-color:#333333;
    color: white;
}

#addContact img{
    filter: invert(100%);
}

nav .icon{
    margin-right: 5px;
}

.rowData{
    padding: 15px;
    border-bottom: 1px solid black;
    padding: 20px;
    padding-left: 0px;
    margin-left: 30px;
    flex-grow: 1;
}

.icon{
    width: 30px;
}

.vcardInformationRow{
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    align-items: center;
}

.vcardInformationRow:last-child div{
    border-bottom: none;

}

#vcardResume{
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    align-items: center;
    margin-top: 40px;

}
.headerResponsive {
    width: 100%;
    height: 200px;
    background-color: #ffffff;
}

#vcardBody{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 600px;
    margin-top: -200px;
    background-color:  #f1f1f1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#vcardAvatar{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-top: 10px;
}

#root{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

#top{
    flex-grow: 1;
}

footer{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    color: white;
}

.informationRowTitle{
    color: gray;
}


@media screen and (max-width: 800px) {

    #vcardBody{
        box-shadow: none;
        width: 100%;
    }

    header{
        height: 300px;
    }

    #vcardHeader{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

}


h2{
    color: black;
}

h1{
    margin: 5px;
}

a{
    text-decoration: none;
    color: black;
}

footer  a{
    padding: 10px;
}

#button{
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 5px;
}
#button:last-child{
    margin-right: none;
}

#altyorLink{
    margin-top: 20px;
}

#resume .rowData{
    border-bottom: none;
}

#logo{
    height: 30px;
    margin-bottom: 10px;
}